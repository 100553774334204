import Headroom from 'headroom.js';

import { breakpoints } from '../constants/breakpoints';

export let stickyHeaderHeadroom: Headroom | null = null;

const initStickyHeader = () => {
  const stickyHeader = document.querySelector<HTMLElement>('.js-sticky-header');
  const options = {
    offset: 200,
    tolerance: {
      up: 10,
      down: 0
    },
    classes: {
      initial:
        'max-lg:transition-transform max-lg:duration-300 max-lg:ease-out',
      unpinned: 'max-lg:-translate-y-full',
      pinned: 'max-lg:translate-y-0',
      top: 'max-lg:translate-y-0',
      bottom: 'max-lg:!translate-y-0'
    }
  };

  /**
   *
   * @param headroom
   * @returns whether the internal attribute scrollTracker is initialized or not.
   * This is used in eg. the destroy function. When no initialized on time,
   * this internal error occurs: https://github.com/WickyNilliams/headroom.js/issues/367
   */
  function isScrollTrackerInitialized(
    headroom: Headroom | null
  ): headroom is Headroom & { scrollTracker: unknown } {
    return (
      !!headroom &&
      typeof headroom === 'object' &&
      'scrollTracker' in headroom &&
      headroom.scrollTracker != null
    );
  }

  // Define a callback function for the event listener.
  const handleResize = ({ matches }: { matches: boolean }) => {
    if (!stickyHeader) return;

    if (!matches) {
      stickyHeaderHeadroom = new Headroom(stickyHeader, options);
      stickyHeaderHeadroom.init();
      return;
    }

    if (isScrollTrackerInitialized(stickyHeaderHeadroom)) {
      stickyHeaderHeadroom.destroy();
      stickyHeaderHeadroom = null;
    }
  };

  // return if the screen is tablet or larger
  const mediaQueryList = window.matchMedia(`(min-width: ${breakpoints.lg}px)`);

  handleResize({ matches: mediaQueryList.matches });

  // Safari versions minor of 14 don't support mediaQueryList.addEventListener
  if (typeof mediaQueryList.addEventListener === 'function') {
    mediaQueryList.addEventListener('change', handleResize);
  } else if (typeof mediaQueryList.addListener === 'function') {
    mediaQueryList.addListener(handleResize); // Fallback
  } else {
    console.error('MediaQueryList does not support event listeners.');
  }
};

export { initStickyHeader };
