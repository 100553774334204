import { breakpoints } from '@/shared/constants/breakpoints';

import { stickyHeaderHeadroom } from '../instances/sticky-header';

export const isElementVisible = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

let timeoutId: number | NodeJS.Timeout | null = null;
export const scrollIntoView = (element: HTMLElement, offset = 0) => {
  // unpin & freeze sticky header, while we programmatically scrolls
  stickyHeaderHeadroom?.unpin();
  stickyHeaderHeadroom?.freeze();

  const rect = element.getBoundingClientRect();
  const scrollToY = rect.top + window.scrollY - offset;

  window.scrollTo({
    top: scrollToY,
    behavior: 'smooth'
  });

  // NOTE: since the 'scrollend' event is not widely adopted (https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollend_event),
  // the sticky header is re-enabled after... a while, as this differs from browser to browser.
  if (timeoutId) {
    window.clearTimeout(timeoutId);
  }
  timeoutId = window.setTimeout(() => {
    stickyHeaderHeadroom?.unfreeze();
    timeoutId = null;
  }, 1000);
};

export const isMobileView = (width = window.innerWidth) =>
  width < breakpoints.md;

export const isTabletView = (width = window.innerWidth) =>
  width < breakpoints.md && width < breakpoints.lg;

export const isDesktopView = (width = window.innerWidth) =>
  width >= breakpoints.lg;

export const isDesktopXLView = (width = window.innerWidth) =>
  width >= breakpoints.xl;
